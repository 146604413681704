<template>
  <div style="/*margin-top:0.1875rem;*/">
    <div class="home_top">
      <div class="home_left">
        <div><templateYear @refreshYear="refreshYear" /></div>

        <div style="margin-left: 10px;">
          <el-select size="mini" v-model="OrgId" @change="changeOrgPids" placeholder="四川省">
            <el-option v-for="item in treeData" :key="item.id" :label="item.name" :value="item.id"> </el-option>
          </el-select>
        </div>

        <div style="margin-left: 10px;">
          <el-button size="mini" plain icon="el-icon-search" @click="search"></el-button>
        </div>

        <div style="margin-left: 10px;">
          <el-button size="mini" plain icon="el-icon-refresh-right" @click="reset"></el-button>
        </div>
      </div>
    </div>
    <div class="socialDev">
      <socialDevTop ref="socialDevTop" @changeFaOrgId="changeFaOrgId" />
    </div>
    <div class="socialDev">
      <socialDevBottom ref="socialDevBottom" />
    </div>
  </div>
</template>

<script>
import socialDevTop from '@/views/postImmigrationService/HomePage/socialDevelopment/components/socialDevTop/index.vue'
import socialDevBottom from '@/views/postImmigrationService/HomePage/socialDevelopment/components/socialDevBottom/index.vue'
import templateYear from '@/components/templateView/templateYear.vue'
import { _getLevelRegion } from '@/api/keepHome'

export default {
  name: 'HcYmhfIndex',

  data() {
    return {
      treeData: [],
      OrgId: null,
      OrgPids: null
    }
  },
  components: {
    socialDevTop,
    socialDevBottom,
    templateYear
  },

  created() {
    this.getLevelRegion()
  },

  methods: {
    refreshYear(value, years) {
      this.$nextTick(() => {
        this.$refs.socialDevTop.setYears(value, years)
        this.$refs.socialDevBottom.setYears(value, years)
      })
    },
    changeFaOrgId(val) {
      this.$refs.socialDevTop.receiveId(val)
      this.$refs.socialDevBottom.receiveId(val)
    },

    // 子组件迁移过来的方法
    changeOrgPids(value) {
      let obj = this.treeData.find(ele => ele.id == value)
      this.OrgId = value
      this.OrgPids = obj?.pids
      // this.$emit('changeFaOrgId', this.OrgId)
    },
    getLevelRegion(val) {
      let params = {
        startLevel: 2,
        endLevel: 2,
        inclusionLevel: false
      }
      _getLevelRegion(params).then(res => {
        if (res.code == 200) {
          this.treeData = res.data

          console.log(this.treeData, '=======this.treeData======')

          // let provincialCenterId = this.treeData[0].id //默认展开第一个节点
          // this.dck = [provincialCenterId]
          // this.downloadObj = this.treeData[0]
        }
      })
    },
    search() {
      // this.$emit('changeFaOrgId', this.OrgId)
      this.changeFaOrgId(this.OrgId)
    },
    reset() {
      let nowYear = new Date().getFullYear()
      if (nowYear <= 2023) {
        this.year = nowYear - 1
      } else {
        this.year = nowYear - 2
      }

      this.OrgId = null
      this.projectTypeId = null

      // this.$emit('changeFaOrgId', null)
      this.changeFaOrgId(null)
    }
  }
}
</script>

<style lang="scss" scoped>
.home_top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & > .home_left {
    display: flex;
  }
}
.socialDev {
  width: 100%;
  margin-top: 10px;
  padding: 0.125rem;
  background-color: #fff;
  border-radius: 0.05rem;
}
</style>
