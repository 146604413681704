<template>
  <div>
    <el-select size="mini" v-model="year" placeholder="请选择" @change="handleChange">
      <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
    </el-select>
  </div>
</template>

<script>
export default {
  name: 'HcYmhfTemplateYear',

  data() {
    return {
      options: [],
      year: null
    }
  },

  created() {
    this.generateYears()
  },

  methods: {
    generateYears() {
      let minYear = 2022 // 设置最小年度
      let years = []

      let nowYear = new Date().getFullYear()

      if (nowYear - 2 <= minYear) {
        years.push({ value: minYear, label: 2022 + '年度' })
        this.year = minYear
        this.handleChange(this.year, years)
      } else {
        this.year = nowYear - 2
        for (let i = minYear; i < nowYear - 1; i++) {
          years.push({ value: i, label: i + '年度' })
        }
        this.handleChange(this.year, years)
      }
      this.options = years
    },
    handleChange(value, options) {
      let years = options ? options : this.options
      this.$emit('refreshYear', value, years)
    }
  }
}
</script>

<style lang="scss" scoped></style>
